@import './assets/css/variables';

*:focus {
    outline: 0;
}

body {
    background-color: white;
    font-family: 'GothamNarrowBook', Arial, sans-serif;
}

.layout-39 {
    width: 1440px;
    background-color: white;
    margin: 0 auto;
    overflow: hidden;

    .inline-block {
        display: inline-block;
    }
}

.main {
    max-width: 1320px;
}

app-navbar nav#site-specific-nav {
    background: $cofcolordark;

    ul:not(.in-mega) > li:hover {
        background: $cofcolor;
    }
}

.margin {
    &-left {
        &20 {
            margin-left: 20px;
        }
    }

    &-right {
        &15 {
            margin-right: 15px;
        }

        &20 {
            margin-right: 20px;
        }

        &40 {
            margin-right: 40px;
        }
    }
}

// BUTTONS

.buttons-container.flex {
    display: flex;

    &.center {
        justify-content: center;
    }
}

.cof-button {
    background-color: $cofcolor;
    font-size: 16px;
    text-align: center;
    height: 40px;
    width: 210px;
    font-family: 'GothamBook';
    border: 1px solid white;
    color: white;
    border-radius: 1px;

    &:hover {
        background-color: $cofcolorlight;
    }

    &:focus {
        outline: 0;
    }

    &:disabled {
        background-color: rgba(68, 68, 68, 0.1);
        border: none;
        color: rgb(103, 103, 103, 0.5);
        cursor: default;
    }

    &.cof-button-light {
        background-color: $cofcolorlight;
        border-color: $cofcolorlight;

        &:hover {
            background-color: $cofcolor;
            border-color: $cofcolor;
        }

        &:disabled {
            background-color: rgba(68, 68, 68, 0.1);
            border: none;
            color: rgb(103, 103, 103, 0.5);
        }
    }

    &.cof-button-white {
        background-color: white;
        border: 1px solid rgba(68, 68, 68, 0.4);
        color: #676767;

        &:hover {
            color: black;
            border-color: rgba(0, 0, 0, 0.4);
        }

        &:disabled {
            background-color: rgba(68, 68, 68, 0.1);
            border: none;
            color: rgb(103, 103, 103, 0.5);
            cursor: default;
        }
    }
    &.cof-button-red {
        background-color: $medium-red;
        border-color: $medium-red;

        &:hover {
            background-color: $dark-red;
            border-color: $dark-red;
        }
    }
    &.cof-button-green {
        background-color: $light-green;
        border-color: $light-green;

        &:hover {
            background-color: $medium-green;
            border-color: $medium-green;
        }
    }
}

button {
    cursor: pointer;
}

.download-icon {
    display: block;
    width: 20px;
    height: 21px;
    background: url(~src/assets/images/icons/download-icon.svg);
    background-size: 20px;
    background-color: transparent;
}

//statuses
.cof-status {
    font-family: 'GothamBookMedium', Arial, sans-serif;
    width: 80px;
    text-align: center;
    border-radius: 2px;
    border: 1px solid black;
    font-size: 11px;

    &.cof-status-big {
        width: 90px;
        padding: 2px 0;
        font-size: 14px;
    }

    &.cancelled-status {
        color: #e21836;
        border: 1px solid #e21836;
        background: rgba(226, 24, 54, 0.2);
    }

    &.void-status {
        color: #97002e;
        border: 1px solid #97002e;
        background: rgba(151, 0, 46, 0.2);
    }

    &.submitted-status {
        color: #444b0d;
        border: 1px solid #444b0d;
        background: rgba(68, 75, 13, 0.2);
    }

    &.approved-status {
        color: #0066b2;
        border: 1px solid #0066b2;
        background: rgba(0, 102, 178, 0.2);
    }

    &.completed-status {
        color: #769231;
        border: 1px solid #769231;
        background: rgba(135, 182, 17, 0.4);
    }

    &.draft-status {
        color: #b2cc34;
        border: 1px solid #b2cc34;
        background: rgba(178, 204, 52, 0.2);
    }

    &.reviewed-status {
        color: #607208;
        border: 1px solid #639e15;
        background: rgba(68, 231, 36, 0.4);
    }
}

//Accordion
mat-accordion mat-expansion-panel.mat-expansion-panel .mat-expansion-panel-header {
    padding: 8px 18px 8px 24px;
}

.mat-expansion-indicator::after {
    color: $cofcolorlight;
}

.mat-expansion-panel-header {
    .mat-chip.mat-standard-chip {
        background-color: #cceffc;
    }

    .mat-standard-chip {
        font-size: 11px;
        color: #00adef;
        border: 1px solid #0db1ef;
        border-radius: 3px;
        min-height: 24px;
    }

    .mat-basic-chip {
        display: inline-flex;
        font-size: 11px;
        border: 1px solid #0db1ef;
        padding: 0 8px 0 4px;
        border-radius: 3px;
        color: #00adef;
        background-color: #cceffc;
        align-items: center;
        cursor: default;
        min-height: 24px;
    }

    .mat-chip .mat-chip-trailing-icon.mat-icon,
    .mat-chip .mat-chip-remove.mat-icon {
        font-size: 18px;
        height: 18px;
        width: 18px;
        margin-left: 4px;
        color: rgba(0, 0, 0, 0.87);
    }

    .mat-chip-list-wrapper .mat-basic-chip,
    .mat-chip-list-wrapper input.mat-input-element {
        margin: 4px;
    }

    .mat-chip.mat-basic-chip .mat-chip-remove {
        opacity: 0.4;
        cursor: pointer;
    }

    .mat-expansion-indicator {
        margin: 0 4px;
    }
}

//IE fix for mat-chip in mat accordion Bug 1636922, 1656306
/* prettier-ignore */
@media screen and (min-width: 0\0) and (min-resolution: +72dpi), \0screen\,screen\9 {
    mat-accordion mat-expansion-panel.mat-expansion-panel .mat-expansion-panel-header {
        height: auto !important;
    }

    .mat-expansion-panel-header .mat-content {
        flex-wrap: wrap;

        > div {
            flex: 1 1 100%;
        }
    }
}

.results-container {
    .title-date {
        font-family: 'GothamNarrowBold';
        font-size: 20px;
        text-transform: lowercase;
        color: $cofcolor;
    }
    .results-title {
        margin-bottom: 20px;
    }
}

//TABLES

th.mat-header-cell[class*='mat-column-'] {
    font-size: 11.5px;
    color: #222222;
}

td.mat-cell[class*='mat-column-'] {
    font-size: 12.5px;
}

.results-container,
.comments-container {
    td.mat-cell,
    th.mat-header-cell {
        padding: 0 3px;

        &:first-of-type {
            padding-left: 16px;
        }

        &:last-of-type {
            padding-right: 16px;
        }
    }

    th.mat-header-cell {
        padding: 0 2px;
    }

    table tbody tr:nth-child(odd) td {
        background: #ffffff;
    }

    .mat-header-cell {
        font-family: 'GothamNarrowBold', Arial, sans-serif;
        color: #222222;
    }

    th.mat-header-cell.mat-column-mcForecast .mat-sort-header-container {
        justify-content: center;
    }
}

th.mat-header-cell {
    border-bottom: 1px solid #808080;
    border-top: 1px solid #c5c5c5;
    background: #fbfbfb;
}

//mypage

a:not([href]):not([tabindex]) {
    color: $linkcolor;
}

.mypage-subcontainer {
    position: relative;
    background-color: white;
    min-height: 310px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    padding: 30px 30px 30px 30px;
    margin-bottom: 20px;
}

.sub-title {
    justify-content: space-between;
    display: flex;
    align-items: center;
    font-family: 'GothamNarrowBold', Arial, sans-serif;
    font-size: 20px;
    text-transform: lowercase;
    color: $cofcolor;
    margin-bottom: 20px;
}

//Changes for angular materials
.filter-container {
    input.mat-input-element,
    mat-select.mat-select {
        font-weight: 600;
    }
}

.mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: $cofcolorlight;
}

.signoff-container .mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: #9e9e9e;
}

.mat-form-field.mat-focused .mat-form-field-label {
    color: $cofcolor;
}

.signoff-container .mat-form-field.mat-focused .mat-form-field-label {
    color: #9e9e9e;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: $cofcolor;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
    background: $cofcolorlight;
}

.mat-option.mat-selected:not(.mat-option-disabled) {
    color: $cofcolor;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate {
    background: $cofcolorlight;
}

.filter-container {
    .container150 .mat-form-field-infix {
        width: 150px;
    }

    .container300 .mat-form-field-infix {
        width: 300px;
    }

    .container450 .mat-form-field-infix {
        width: 450px;
    }

    .container620 .mat-form-field-infix {
        width: 620px;
    }
}

.mat-column-mcForecast .mat-button-focus-overlay {
    background: none;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $cofcolorlight;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: $cofcolorlight;
}

//checkboxes
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: $cofcolorlight;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
    background-color: $cofcolorlight;
}

//table columns checkboxes
.table-checkboxes {
    .mat-checkbox {
        margin-right: 25px;

        &:last-child {
            margin-right: 0px;
        }
    }

    .mat-checkbox-label {
        font-family: 'GothamNarrowBold', Arial, sans-serif;
        font-size: 12px;
        color: #222222;
    }
}

.mat-autocomplete-panel tr:not(.tr-selected) {
    cursor: pointer;
}

app-multiple-items-selector .mat-form-field-flex {
    cursor: pointer;
}

app-multiple-items-selector .mat-form-field-flex input {
    cursor: pointer;
}

tr.tr-selected td {
    color: #b2b2b2;
}

.mat-autocomplete-panel h4 {
    padding-top: 10px;
}

.mat-chip-list {
    width: 100%;
}

.mat-chip-list input.mat-chip-input {
    width: 100%;
}

_:-ms-fullscreen,
:root .mat-chip-list-wrapper {
    display: block;
}

.lds-hourglass:after {
    content: ' ';
    display: block;
    border-radius: 50%;
    box-sizing: border-box;
    border: 25px solid #0066b2;
    border-color: #0066b2 transparent #0066b2 transparent;
    animation: lds-hourglass 1.2s infinite;
}

.lds-hourglass-small:after {
    content: ' ';
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    box-sizing: border-box;
    border: 10px solid #0066b2;
    border-color: #0066b2 transparent #0066b2 transparent;
    animation: lds-hourglass 1.2s infinite;
}

@keyframes lds-hourglass {
    0% {
        transform: rotate(0);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    50% {
        transform: rotate(900deg);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    100% {
        transform: rotate(1800deg);
    }
}

.mat-expansion-panel {
    position: relative;
}

.main .mat-tab-group.mat-primary .mat-ink-bar,
.main .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: $cofcolorlight;
}

a.mat-tab-link {
    &:visited,
    &:hover {
        border-bottom: none;
        color: rgba(0, 0, 0, 0.87);
    }
}

.main .mat-tab-link:not(.mat-tab-label-active) {
    background-color: rgba(216, 216, 216, 0.4);
}

//changed document mat-tab
.mat-tab-header {
    border-bottom: none;
}

.mat-tab-body {
    padding: 30px;

    &-wrapper {
        background-color: white;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    }
}

.mat-tab-label {
    background-color: rgba(216, 216, 216, 0.4);

    &-active {
        color: black;
        background-color: white;
    }
}

//Table header filters
.mat-calendar-body-selected {
    background-color: $cofcolorlight;
}

app-asc-desc-order .sort-button.mat-icon-button {
    line-height: 24px;
}

app-header-checklist-filter .button-container {
    margin-bottom: 10px;
}

app-header-checklist-filter {
    .mat-list-base .mat-list-option .mat-list-item-content {
        height: 32px;
        padding: 0 2px;
    }

    .mat-list-base .mat-list-option.mat-list-option .mat-list-item-content .mat-list-text {
        padding-left: 8px;
    }

    .mat-accent .mat-pseudo-checkbox-checked {
        background: $cofcolorlight;
    }
}

.page-counter {
    button.mat-paginator-navigation-next.mat-icon-button {
        margin-left: 58px;
    }

    &.paginator {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: rgba(0, 0, 0, 0.54);
        padding: 0 8px;
        width: 1300px;

        input {
            font-family: Roboto, 'Helvetica Neue', sans-serif;
            font-size: 12px;
            text-align: center;
            margin-left: 8px;
            border: none;
            outline: none;
            width: 50px;
            color: rgba(0, 0, 0, 0.54);
            position: relative;
            right: 150px;
            cursor: text;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }
}

/* //Validation status

.validation-status {
    font-family: 'GothamBookMedium';
    width: 90px;
    text-align: center;
    line-height: 20px;
    padding: 2px 0;
    border-radius: 2px;
    font-size: 11px;
}

.validation-status.completed-status {
    color: #00adee;
    border: 1px solid #00adee;
    background: rgba(0, 173, 238, 0.2);
}

.validation-status.changes-reviewed-status {
    color: #444b0d;
    border: 1px solid #444b0d;
    background: rgba(68, 75, 13, 0.2);
}

.validation-status.in-progress-status {
    color: #4b5216;
    border: 1px solid #4b5216;
    background: rgba(68, 75, 13, 0.4);
}

.validation-status.not-started-status {
    color: #b2cc34;
    border: 1px solid #b2cc34;
    background: rgba(178, 204, 52, 0.2);
}

app-history-popup {
    .validation-status {
        &.completed-status {
            display: block;
            padding: 0;
        }

        &.in-progress-status {
            display: block;
            padding: 0;
        }

        &.not-started-status {
            display: block;
            padding: 0;
        }

        &.changes-reviewed-status {
            display: block;
            line-height: 1;
            padding: 2px 0;
        }
    }
} */

.checkbox-section .mat-checkbox-layout .mat-checkbox-inner-container {
    margin: initial;
    margin-right: 8px;
}

//TABLE
//Register-Table-Results
//Register-Table-Cursor
.results-container tbody td a {
    cursor: pointer;
}

.results-container .mat-cell a:hover {
    color: $linkcolor;
    border-bottom: 1px solid $linkcolor;
}

//tooltip
mat-tooltip-component .cofftooltip {
    background-color: white;
    color: black;
    font-size: 14px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
}

.mat-tooltip.linebreak {
    white-space: pre-line;
}

.mat-select-value-text {
    font-family: 'GothamNarrowBold';
}

.main > .navigator .mat-icon {
    margin-right: 2px;
}

.main > a.navigator {
    cursor: pointer;
    color: $cofcolorlight;
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-top: 24px;
    margin-bottom: -8px;
}

.main > a:hover.navigator {
    color: $cofcolorlight;
    border-bottom: none;
}

th[app-col-resize] {
    cursor: col-resize;
}

.hover-pointer {
    cursor: pointer;
}

.hover-pointer.resize-column {
    cursor: col-resize;
}

.multiple-items-error .mat-form-field-label {
    color: red;
}

.multiple-items-error .mat-form-field-underline {
    background-color: red;
}

td.mat-column {
    &-subject {
        word-break: break-all;
        word-break: break-word;
    }
}

.scroll {
    overflow-x: auto;

    table.mat-table {
        table-layout: fixed;
    }
}

.loader {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(128, 128, 128, 0.6);
    z-index: 1000;
}

.hover-pointer {
    cursor: pointer;
}

.hover-pointer.resize-column {
    cursor: col-resize;
}

.header-underline {
    text-decoration: underline;
}

.checkbox-section .mat-checkbox-layout {
    white-space: nowrap;
}

svg.ngx-charts:not(:root) {
    overflow: visible;
}

.sort-container {
    display: flex;
    align-items: center;

    &.center {
        justify-content: center;

        span {
            text-align: center;
        }
    }

    .sort-icon {
        display: flex;
        align-self: center;
        height: 18px;

        .mat-icon {
            height: 18px;
            width: 18px;
            font-size: 18px;
        }
    }
}

.nobreak.mat-column-contractno {
    white-space: nowrap;
}

.jodit-container {
    position: relative;
    border: 1px solid black;
    padding: 5px;
    cursor: pointer;
    min-height: 150px;

    &.description-error {
        border: 1px solid red;
    }

    &.description-disabled {
        pointer-events: none;
        border: 1px solid gray;
        background-color: rgb(235, 235, 228);
    }
}

.jodit-loader {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(128, 128, 128, 0.6);
}

// MEDIA QUEREIES
//overwrite Next.css to not hide navbar & tables
@media (max-width: 1199.98px) {
    nav#site-specific-nav {
        display: block;
        width: 100%;
    }

    div table {
        margin-top: 0;
    }

    div table tr {
        th,
        td {
            display: table-cell;
        }
    }
}

@media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
    app-multiple-items-selector .mat-chip-list-wrapper input.mat-input-element {
        margin: 2px;
    }
}

@media (resolution: 144dpi) {
    body {
        zoom: (0.85);
    }
}

@media print {
    body.print-callof {
        zoom: (0.75);
    }
}

//subsystem - field change summary printout
@media print {
    .doNotPrint {
        display: none !important;
    }

    .title-container .title {
        color: #000 !important;
    }

    .main h4 {
        color: #464646 !important;
    }

    thead {
        display: table-header-group;
    }

    .background {
        background-color: white !important;
    }

    input[readonly='true'],
    .mat-select-value-text,
    .mat-input-element:disabled,
    .mat-select-disabled .mat-select-value,
    .mat-chip.mat-standard-chip.mat-chip-disabled,
    .mat-form-field-appearance-legacy .mat-form-field-label,
    .mat-radio-button.mat-radio-disabled .mat-radio-label-content,
    .documents-container label {
        font-family: 'GothamNarrowBook', Arial, sans-serif !important;
        color: #000 !important;
        opacity: 1 !important;
        font-weight: 400 !important;
    }

    .mat-form-field-appearance-legacy .mat-form-field-underline {
        background-color: #808080 !important;
        background-image: none !important;
    }

    mat-icon.mat-icon.mat-chip-remove {
        display: none;
    }

    .jodit-container {
        background-color: white !important;
        border: none !important;
    }

    mat-chip.mat-standard-chip {
        background-color: white !important;
        font-size: 16px;
    }
}
